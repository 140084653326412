body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#eee !important;
  font-family: CairoRegular !important;

  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: CairoLight;
  src: url('fonts/Cairo-Light.ttf');
}
@font-face {
  font-family: CairoRegular;
  src: url('fonts/Cairo-Regular.ttf');
}
@font-face {
  font-family: CairoBold;
  src: url('fonts/Cairo-Bold.ttf');
}
@font-face {
  font-family: MontserratBold;
  src: url('fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: MontserratRegular;
  src: url('fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: MontserratLight;
  src: url('fonts/Montserrat-Light.ttf');
}
.ar{
  color:darkgreen;
  text-align: right;
  direction: rtl;
}
.ar-light{
  font-family: CairoLight;
}
.ar-regular{
  font-family: CairoRegular;
}
.ar-bold{
  font-family: CairoBold;
}
.se-light{
  font-family: MontserratLight;

}
.se-regular{
  font-family: MontserratRegular;

}
.se-bold{
  font-family: MontserratBold;

}
.se{
  color:#005293;
}