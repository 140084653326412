.registerBox {
  margin-top: 130px;

  & .loginTabs {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    & .tab {
      width: 49%;
      text-align: center;
      background-color: white;
      padding: 20px 0px;
      font-weight: bold;
      margin: 0px 1%;
      border-radius: 10px;
      cursor: pointer;
    }
    & .tab:hover {
      background-color: #005293;
      color: white;
    }
    & .tab.active {
      background-color: #005293;
      color: white;
    }
  }
}
.testimonials {
  background-color: white;
}

.kofeyaback {
  background: url("../../assets/kofeya.png") repeat;
  width: 100%;
  height: 100px;
  margin: 10px 0px;
}

@media (max-width: 600px) {
  .registerBox .loginTabs {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }
}
