.introduction.mainIntroduction {
  margin-top: 100px;
  font-size: 20px;
}
.introductioncontainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .introText {
    width: 100%;
    text-align: center;
  }
  $breakpoint-mobile2: 600px;
  @media (max-width: $breakpoint-mobile2) {
    & .introText {
      font-size: 12px !important;
    }
  }
}
.introduction {
  text-align: center;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 20px 0px;
  margin: 20px 0px;
  font-size: 12px;
  background: hsl(0, 0%, 100%);
  margin-top: 30px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,eeeeee+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #ffffff 0%,
    #eeeeee 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #ffffff 0%,
    #eeeeee 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    #ffffff 0%,
    #eeeeee 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  margin-bottom: 0px;
  & p {
    margin: 0px;
    padding: 0px;
  }
  & .centerText {
    text-align: center;
  }

  & .introText {
    & span {
      font-weight: bold;
    }
  }
}
