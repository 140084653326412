.Books {
  margin-bottom: 0px;

  & .ar {
    text-align: right;
    direction: rtl;
  }

  & .react-multiple-carousel__arrow--left {
    left: 0px;
    background: #015293;
    top: 0px;
  }
  & .react-multiple-carousel__arrow--right {
    right: 2px;
    background: #015293;
    top: 0px;
  }
  & .react-multi-carousel-dot-list {
    bottom: -30px;
  }
  & .react-multi-carousel-list {
    overflow: hidden;
    padding-top: 60px;
  }
  .book.arabic {
    text-align: right;
  }
  .book {
    width: 97%;
    display: inline-block;
    background: white;
    margin: 1%;
    padding: 1%;
    border-radius: 10px;
    & .book_image {
      width: 20%;
      display: inline-block;
      vertical-align: top;
      border-radius: 10px;
      overflow: hidden;
      & img {
        width: 100%;
      }
    }

    & .book_content {
      display: inline-block;
      width: 75%;
      margin-left: 3%;
      border-left: 1px solid #eee;
      padding-left: 3%;
      & .book_title {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
      }

      & .book_body {
        font-size: 12px;
        width: 95%;
        float: left;
        height: 54px;
      }

      & .book_pdf {
        float: left;
        margin-top: 20px;
        width: 100%;
        padding-bottom: 10px;
        text-align: center;
        & .btnBox {
          & button {
            background: none;
            border: none;
            color: white;
            width: 50%;
            background: #015293;
            color: gold;
            text-align: center;
            padding: 1% 2%;
            border-radius: 15px;
            box-shadow: 0px 0px 3px gray;
            font-size: 12px;
            &:hover {
              color: gold;
            }
          }
        }
      }
    }
  }

  & .book.arabic {
    & .book_content {
      display: inline-block;
      width: 75%;
      margin-right: 3%;
      margin-left: 0px;
      border-right: 1px solid #eee;
      padding-right: 3%;
      border-left: none;
      margin-left: 0px;
      padding-left: 0px;

      & .book_title {
        font-size: 14px;
      }

      & .book_body {
        font-size: 12px;
        float: right;
      }

      & .book_pdf {
        margin-top: 20px;
        width: 95%;
        & a {
          background: #015293;
          color: gold;
          padding: 1% 2%;
          border-radius: 15px;
          box-shadow: 0px 0px 3px gray;
          float: left;
          margin-left: 17px;
          & img {
            width: 17px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.arabic.modal-header {
}
