.translateHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & .headertext {
    height: 50px;
    display: flex;
    flex-direction: row-reverse;
  }

  & .closeBtn {
    position: absolute;
    height: 88%;
    padding: 0px 20px;
    font-weight: bold;
    top: -42px;
    background: red;
    color: white;
    font-size: 10px;
    border-radius: 10px 10px 0px 0px;
  }

  & .questionImage {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    cursor: pointer;
  }

  & .questionButton {
    padding: 0;
    margin: 0;
    background: none !important;
    border: none;
    height: 30px;
    margin-right: 10%;

    & :active {
      background: none;
    }
  }

  & .left {
    flex: 1;
    text-align: left;
  }
  & .right {
    flex: 1;
    text-align: right;
  }
  & .arabic_Title {
    display: flex;
    flex-direction: row-reverse;
  }

  & .h5Label {
    width: auto;
  }
}
