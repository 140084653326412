.infoSection {
  margin: 0px 0px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,ffffff+50,eeeeee+100 */
  background: #eeeeee; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #eeeeee 0%,
    #ffffff 50%,
    #eeeeee 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #eeeeee 0%,
    #ffffff 50%,
    #eeeeee 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #eeeeee 0%,
    #ffffff 50%,
    #eeeeee 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#eeeeee',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  padding: 20px 0px;
}
