.successPayment {
  & .tickIcon {
    width: 100px;
  }
  & .ar {
    direction: rtl;
    text-align: right;
    color: black;
  }

  $breakpoint-mobile2: 600px;
  @media (max-width: $breakpoint-mobile2) {
    margin-top: 25%;
    text-align: center;
    & .ar {
      text-align: center;
    }
  }
}
