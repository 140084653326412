.contactUsForm {
  margin-bottom: 30px;
  .contactSubmitted {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .contactSubmitted img {
    width: 100px;
  }
  .contactSubmitted .submittedMessage {
  }
  .contactHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .contactHeader div {
    font-weight: bold;
  }
}
