.accountContainer {
  margin-top: 120px;
}
.accountHeader {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}
.accountHeader h1 {
}
.accountInfo {
  width: 50%;
}
.accountSubscription {
  width: 50%;
}
.accountBody {
  display: flex;
  flex-direction: row-reverse;
}
.accountContainer {
  padding-bottom: 40px;
}
.goBackApp {
  text-align: center;
}

$breakpoint-mobile2: 600px;
@media (max-width: $breakpoint-mobile2) {
  .accountBody {
    display: flex;
    flex-direction: column;
  }
  .accountInfo {
    width: 100%;
  }
  .accountSubscription {
    width: 100%;
  }
}
