.AboutUs {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+1,eeeeee+1,ffffff+40,ffffff+64,ffffff+90,ffffff+90,eeeeee+100 */
  background: #eeeeee; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #eeeeee 1%,
    #eeeeee 1%,
    #ffffff 40%,
    #ffffff 64%,
    #ffffff 90%,
    #ffffff 90%,
    #eeeeee 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #eeeeee 1%,
    #eeeeee 1%,
    #ffffff 40%,
    #ffffff 64%,
    #ffffff 90%,
    #ffffff 90%,
    #eeeeee 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #eeeeee 1%,
    #eeeeee 1%,
    #ffffff 40%,
    #ffffff 64%,
    #ffffff 90%,
    #ffffff 90%,
    #eeeeee 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#eeeeee',GradientType=1 ); /* IE6-9 */

  & .ar {
    direction: rtl;
  }
  & .bodysec {
    /*     font-size: 12px;
    height: 500px;
    overflow: auto; */
  }
  & .aboutUs_sec_2 {
    font-size: 1.1em;
  }
  & .author_name {
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  & .AboutTitle {
    width: 100%;
    font-weight: bold;
    font-size: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;

    & .left {
      text-align: left;
    }
    & .right {
      text-align: right;
    }
  }
  .language {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .language button {
    margin: 0 10px;
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    flex: 1;
    cursor: pointer;
  }
  .language button.active {
    background: lightblue;
  }
}
