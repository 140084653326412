.AccountInfoBox {
  .inputElement {
    display: flex;
    flex-direction: row-reverse;
    margin: 36px 0px;
    justify-content: space-between;
  }
  .inputElement label {
    width: 250px;
    text-align: right;
  }
  .inputElement input {
    width: 50%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
  }
  .AccountInfoBox {
    width: 50%;
  }
  .AccountInfoBox .container {
  }
  h2 {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    background-color: white;
    padding: 10px 0px;
  }
  .updateBtn {
    width: 100%;
    margin-top: 20px;
    background-color: green;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
  }
}
