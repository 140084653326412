.SubscriptionBigBiox {
  .inputElement {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px 0px;
    justify-content: space-between;
  }
  .inputElement label {
    width: 250px;
    text-align: right;
  }
  .inputElement input {
    width: 50%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .AccountInfoBox {
    width: 50%;
  }
  .AccountInfoBox .container {
  }
  h2 {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    background-color: white;
    padding: 10px 0px;
  }
  .updateBtn {
    width: 100%;
    margin-top: 20px;
    background-color: green;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
  }
  .SubscriptionPaymentBox {
    background-color: white;
    padding: 10px 0px;
  }
  .subscriptionStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px;
  }
  .subscriptionStatus div.center {
    text-align: center;
  }
  .SubscriptionPaymentBox {
    padding: 10px;
    text-align: center;
  }
  .greenBox {
    background-color: green;
    color: white;
    padding: 0px 10px;
    border-radius: 5px;
  }
  .activeImage {
    width: 300px;
  }

  .subscriptionStatus div {
    flex: 1;
  }
}
@media (max-width: 600px) {
  .SubscriptionBigBiox .subscriptionStatus {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
  }
}
