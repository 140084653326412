.Contactus {
  text-align: center;
  & .contacticondiv {
    text-align: center;
    & .Contactus_Icon {
      width: 50%;
      margin: 0 auto;
    }
  }
  & .Contactus_Container {
    width: 300px;
    margin: 0 auto;
    text-align: right;
  }
  & .aboutUs_sec_1 {
  }
  & .aboutUs_sec_2 {
  }
  & .socialLinks {
  }
  & .facebookLink {
    text-align: right;
    margin: 10px 0px;
  }
  & .websiteLink {
    text-align: right;
    margin: 10px 0px;
  }
  & .facebook_icon {
    padding-left: 10px;
    width: 30px;
  }
  & .website_icon {
    padding-left: 10px;
    width: 30px;
  }
}
