.Login {
  margin-top: 200px;
  & .password {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
  }
  & .error {
    width: 100%;
    text-align: right;
    color: red;
    margin-top: 10px;
  }
  & .note {
    width: 100%;
    text-align: center;
  }
}
