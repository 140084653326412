.SearchhResult {
  margin-top: 10px;

  & .resultTable {
    width: 100%;
  }
  & .resultDivs {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    & h2 {
      font-size: 18px;
    }
  }
  & .resultDiv {
    flex: 1;
    background: white;
    margin: 1px;
    padding: 5px;
    border: 1px solid #eee;
  }
  & .headerDiv {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 1px;
    font-weight: bold;
    font-size: 18px;
  }
  & .swedishHeaderRs {
    text-align: left;
  }
  & .englishHeaderRs {
    text-align: center;
  }
  & .arabicHeaderRs {
    text-align: right;
  }
  & .headerResul {
    flex: 1;
    margin: 1px;
  }
  & .swedishRs {
    text-align: left;
  }
  & .englishRs {
    text-align: center;
  }
  & .arabicRs {
    text-align: right;
    direction: rtl;
  }
  & .SearchResult_header {
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
    padding: 10px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+1,eeeeee+1,ffffff+40,ffffff+57,ffffff+57,eeeeee+100 */
    background: #eeeeee; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #eeeeee 1%,
      #eeeeee 1%,
      #ffffff 40%,
      #ffffff 57%,
      #ffffff 57%,
      #eeeeee 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #eeeeee 1%,
      #eeeeee 1%,
      #ffffff 40%,
      #ffffff 57%,
      #ffffff 57%,
      #eeeeee 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #eeeeee 1%,
      #eeeeee 1%,
      #ffffff 40%,
      #ffffff 57%,
      #ffffff 57%,
      #eeeeee 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#eeeeee',GradientType=1 ); /* IE6-9 */

    & .se {
    }
    & .ar {
      float: right;
    }
  }
  & .ResultBox {
    background: white;
    padding: 1%;
    border-radius: 5px;
    line-height: 85px;
    & .oneResult {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
    }

    & .arabicContent {
      direction: rtl;
    }
  }
  & .ResultBox {
    text-align: center;
    text-align: center;
    box-shadow: 0px 0px 5px grey inset;
    border-radius: 10px;
    min-height: 100px;
    line-height: 28px;
    text-align: center;
    & .copy {
      font-size: 10px;
    }
  }

  & .ResultBox.ar {
    // text-align: right;
    // direction: rtl;
  }
  & .ResultBox span {
    padding-left: 0px !important;
    line-height: 100px;
  }

  $breakpoint-mobile2: 600px;
  @media (max-width: $breakpoint-mobile2) {
    & .resultDivs {
      flex-direction: column;
    }
  }
}
