.arabictoswedish {
  & .bold {
    font-weight: bold;
    color: #000;
  }
  & .error {
    color: red;
    font-size: 15px;
    font-weight: bold;
  }
  & .closeBtn {
    position: absolute;
    height: 88%;
    padding: 0px 20px;
    font-weight: bold;
    top: -52px;
    background: red;
    color: white;
    font-size: 12px;
    left: 0px;
  }
  & .hidden {
    display: none;
  }
  & .autocomplete {
    margin-top: 30px;
    position: relative;
    right: 0 !important;

    & .even {
      background-color: #f9f9f9;
    }

    & .searchBtn {
      width: 20%;
      float: left;
      padding: 8.5px 0px;
      background: #005293;
      height: 43px;
      color: gold;
      border-radius: 10px 0px 0px 10px;
      outline: none;
      line-height: 0;
    }
    & div {
      width: 80%;
      position: relative;
      & div {
        width: 100%;
        margin: 0px;
        padding: 0px;
        z-index: 10;
        padding-right: 0px !important;
        text-align: right;
        position: abs;
        position: absolute !important;
        top: 44px !important;
        right: 0px !important;
        left: 0 !important;
        min-height: 300px !important;

        & div {
          width: auto !important;
          position: relative !important;
          top: 0 !important;
          text-indent: 30px !important;
          height: auto !important;
          min-height: auto !important;
        }
      }
    }
    & input {
      width: 100%;
      text-align: right;
      font-family: CairoLight;
      outline: none;
      padding: 2%;
      border-radius: 0px;
      border: 1px solid lightgrey;
      float: right;
      height: 43px;
    }
    & .insideautocomplete {
      padding: 5px 0px;
      margin: 0px;
      float: right;
      margin-right: 0px;
      width: 100% !important;
    }
  }

  & .headertext {
  }
}

$breakpoint-mobile: 320px;
$breakpoint-mobile2: 600px;
$breakpoint-mobile3: 768px;
$breakpoint-mobile4: 1024px;

@media (max-width: $breakpoint-mobile4) {
  .arabictoswedish {
  }
}

@media (max-width: $breakpoint-mobile3) {
  .arabictoswedish {
    margin-top: 20px;
  }
}

@media (max-width: $breakpoint-mobile2) {
  .arabictoswedish {
    & .autocomplete {
      & .searchBtn {
        height: 37px;
        line-height: 0;
      }
    }
  }
}

.tableResult {
  width: 100%;
  text-align: center;

  & tr {
    & td {
      width: 50%;
    }
    & td.swedishTD {
      text-align: left;
      & span {
        color: red;
        font-weight: bold;
        line-height: 3;
      }
    }
    & td.arabicTD {
      text-align: right;
      direction: rtl;

      & span {
        color: red;
        font-weight: bold;
        line-height: 3;
      }
    }
  }
  & tr:nth-child(even) {
    background: #eee;
  }
}

@media (max-width: $breakpoint-mobile) {
  .arabictoswedish {
  }
}
