.header {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+63,eeeeee+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 63%,
    #eeeeee 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffffff 63%,
    #eeeeee 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff 63%,
    #eeeeee 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
  font-size: 12px;
  line-height: 90px;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  position: fixed;
  top: 0px;
  z-index: 100;
  & a {
    cursor: pointer;
  }
  & .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  & .col-5 {
    margin: 0px;
    padding: 0px;
    text-align: center;
  }
  & .col-6 {
    font-size: 10px;
  }
  & .col-7 {
    margin: 0px;
    padding: 0px;
    text-align: left;
  }

  & .nav-item {
    border-bottom: 5px solid grey;
    margin: 0 1%;
    width: 20%;

    :hover {
      color: gold !important;
    }
    & a {
      color: black;
      text-decoration: none;

      div.se {
        text-align: center;
        line-height: 30px;
      }
      div.ar {
        text-align: center;
        line-height: 30px;
      }
      &.active div {
        color: gold !important;
      }
    }
  }
  & .navbar-collapse {
    & .nav-item {
      width: 100%;
      border-bottom: 1px solid lightgrey;
    }
  }
  & .navbar-brand {
  }
  & .navbar {
    padding: 0px;
  }
  & .navbar-nav {
    width: 100%;
  }
  & .centerlogo {
    text-align: center;
    border-bottom: 0px;
    & img {
      width: 100px;
    }
  }

  & .navbar-expand-md {
    & .nav-item {
      width: 100%;
      border-bottom: 1px solid #eee;
    }
  }
  .myAccount {
    display: flex;
    justify-content: flex-end;
  }
  .myAccount {
    .nav-item {
      width: 300px;
      border-bottom: 0px;
    }
  }
}

.fp-tableCell {
  vertical-align: top !important;
}

.userIcon {
  width: 50px;
}
.linkHref {
  text-decoration: none;
}
