.UsedTerms {
  //background: url("../../assets/kofeya.png") repeat;
}
.terms {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns of equal width */
  gap: 16px; /* Space between grid items */
  & .usedTerm {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    transition: transform 0.2s ease;
    & .imageDiv {
      & img {
        width: 100%;
        height: 150px;
        border-radius: 10px;
      }
    }
    & .arabicTitle {
      text-align: right;
    }
  }
  .size-2 {
    grid-column: span 2; /* Span 2 columns */
  }
  $breakpoint-mobile2: 600px;
  @media (max-width: $breakpoint-mobile2) {
    display: grid;
    grid-template-columns: none; /* 4 columns of equal width */
    gap: 16px; /* Space between grid items */
    .size-2 {
      grid-column: span 1; /* Span 2 columns */
    }
  }
}
