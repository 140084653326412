.Comments{
    & .CommentScroll{
       // height:350px;
       // overflow: auto;
    }
    & .CommentDiv{
        width:98%;
        border-bottom:1px solid #eee;
        padding-bottom:10px;
        font-size: 12px;
        padding:1%;
        border-radius:10px;
        margin-bottom:20px;
        & .commentbox{
            min-height:50px;
        }
        & .commentWriter{
            margin : 0 1%;
            & div{
                font-size: 10px;
                width: 21%;
                padding: 1%;
                border-radius: 10px;
                background: #DCEAF5;
            }
        }
        & .commentWriter.se{
            & div{
                float: right;
            }
        }
    }
    & .CommentDiv:nth-child(even){
        background:white;
    }
  
}