.BoxModal {
  & .Box {
    background: white;
    padding: 10px;
    position: relative;
    margin-top: 50px;
    border-radius: 20px;
    & .icon {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: none;
      top: -30px;
      & img {
        height: 75px;
        background: white;
        padding: 10px;
        border-radius: 40%;
      }
    }
    & .title {
      & div {
        display: inline;
        width: 50%;
        font-size: 15px;
        height: 50px;
      }
    }
    & .introduction {
      margin-top: 10px;
      overflow: hidden;
      font-size: 22px;
    }
    & .btnBox {
      text-align: center;
    }
    & .ViewBtn {
      background: #015293;
      margin: 0 auto;
      color: white;
      &:hover {
        color: gold;
      }
    }
  }
}

.ModalBox {
  & .modal-content {
    padding: 5%;
    background: #f6f6f6;
  }
}
