.Payment {
  text-align: center;

  & .list {
    & li {
      text-align: left;
    }
  }

  & .list-ar {
    direction: rtl;
    & li {
      text-align: right;
    }
  }

  .stripePayment {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid #333;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    background-image: url("../../assets/lock.png");
    background-color: white;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: top right;
    padding-right: 40px;

    & .stripeLogo {
      width: 200px;
    }
    & .stripeText {
      font-size: 80%;
      margin-top: 10px;
    }
  }

  & .language {
    button {
      margin: 0 5px;
      background-color: white;
      width: 100px;

      &.active {
        background-color: #005293;
        color: gold;
      }
    }
  }
  & .paymentOptions {
    display: flex;
    justify-content: center;
    flex-direction: row;
    & .payOption {
      border: 1px solid #000;
      margin: 5px;
      border-radius: 10px;
      padding: 10px;
      background-color: white;
      position: relative;
      margin-top: 50px;
      &.monthly {
        & .subscriptionDetails {
          //height: 100px;
          & .details {
            margin-top: 50px;
          }
        }
      }
      &:hover {
        background-color: #eee;
      }
      & label {
        font-weight: bold;
        font-size: 20px;
      }
    }
    & .subscriptionDetails {
      //height: 150px;
      margin-top: 10px;
    }
    & .price {
      font-size: 30px;
      font-weight: bold;
      position: absolute;
      top: -40px;
      right: -0px;
      background: #005293;
      color: gold;
      padding: 0px 40px;
      border-radius: 10px;
    }
    & .details {
      margin-top: 10px;
    }
    & .payNow {
    }
    & .discountBox {
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: green;
      color: white;
      width: 300px;
      align-self: center;
      margin: 0 auto;
      border-radius: 10px;
      margin-top: 20px;
      & .youSave {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }
      & .discount {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  @media (max-width: 768px) {
    & .paymentOptions {
      flex-direction: column;
      & .payOption {
        border: 1px solid #000;
        margin: 5px;
        border-radius: 10px;
        padding: 10px;
        background-color: white;
        position: relative;
        margin-top: 50px;
        &.monthly {
          & .subscriptionDetails {
            //height: 100px;
            & .details {
              margin-top: 50px;
            }
          }
        }
        &:hover {
          background-color: #eee;
        }
        & label {
          font-weight: bold;
          font-size: 20px;
        }
      }
      & .subscriptionDetails {
        //height: 150px;
        margin-top: 10px;
      }
      & .price {
        top: -44px;
      }
      & .details {
      }
      & .payNow {
      }
      & .discountBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: green;
        color: white;
        width: 300px;
        align-self: center;
        margin: 0 auto;
        border-radius: 10px;

        & .youSave {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
        }
        & .discount {
          font-size: 30px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
    .stripePayment {
      flex-direction: column;
      & .stripeLogo {
        width: 100%;
      }
      & .stripeText {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
}
