.mobile_box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 20px;
  padding: 60px;
  justify-content: center;

  & .leftSec {
    text-align: center;
    & img {
      width: 100px;
      margin-top: 50%;
      margin-bottom: 50px;
    }
    & a {
      color: black;
    }
  }

  & .mobile_image {
    text-align: center;
    & img {
      width: 50%;
      margin: 0 auto;
    }
  }

  & .rightSec {
    text-align: center;
    & img {
      width: 100px;
      margin-top: 50%;
      margin-bottom: 50px;
    }
    & a {
      color: black;
    }
  }

  & .downloadLabel {
    float: left;
    width: 100%;
  }

  h1 {
    color: #005293;
    font-size: 16px;
  }

  & .mobile {
    display: none;
  }
}

@media (max-width: 420px) {
  .mobile_box {
    width: 100%;
    flex-direction: column;

    & .desktop {
      display: none;
    }

    & .mobile {
      display: block;
      margin-bottom: 50px;
    }
    & .leftSec {
      width: 100%;
      & img {
        margin: 0px;
      }
    }
    & .rightSec {
      width: 100%;
      & img {
        margin: 0px;
      }
    }
  }
}
