.Book{

    & .book.swedish{
        & img{
            width:100%;
        }
        & .book_title{

        }

        & .book_body{

        }

        & .book_pdf{
            margin-top: 20px;
            width:100%;
            & a{
                background: #015293;
                color: gold;
                padding: 1% 2%;
                border-radius: 15px;
                box-shadow: 0px 0px 3px gray;
                float: right;
                font-size: 12px;
                & img{
                    width: 17px;
                    margin-right: 10px;
                }
                
            }
        }




    }

    & .book.arabic{
        & img{
            width:100%;
        }
        & .book_title{

        }

        & .book_body{

        }

        & .book_pdf{
            margin-top: 20px;
            width:100%;
            & a{
                background: #015293;
                color: gold;
                padding: 1% 2%;
                border-radius: 15px;
                box-shadow: 0px 0px 3px gray;
                font-size: 12px;
                float: left;
                & img{
                    width: 17px;
                    margin-left: 10px;
                }
                
            }
        }




    }



}