.cancelSubscription {
  width: 97%;
  text-align: center;
  background: darkred;
  color: white;
  padding: 20px 0px;
  border-radius: 5px;
  margin: 10px 10px 0px 10px;
  cursor: pointer;

  .askDescription {
  }
  .questionForm {
    width: 100%;
  }
  .InputName {
  }
  .Input {
    width: 100%;
  }
  .InputEmail {
  }
  .InputMessage {
  }
  .cancelSubscriptionBtn {
    justify-content: space-between;
    display: flex;
    padding: 0px 10px;
  }
}
$breakpoint-mobile2: 600px;
@media (max-width: $breakpoint-mobile2) {
  .askDoctor {
    width: 180px;
  }
}
