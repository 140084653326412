.UsedTermsElement {
  & .searchBox {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #373737;
    margin: 10px 0px;
  }
  & .searchInput {
    width: 95%;
    border: none;
    padding: 5px;
    outline: none;
  }
  & .searchIcon {
    width: 5%;
  }

  & .title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    color: #005293;
  }

  & .termBody {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 15px;
    }

    h3 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
  .terms {
    display: flex;
    flex-direction: column;
    & .usedTermrecord {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      border: 1px solid #333;
      margin-bottom: 20px;
      padding: 10px;

      & .element {
        flex: 1;
      }

      & .arabicTitle {
        text-align: right;
        direction: rtl;
      }

      & .label {
        font-weight: bold;
        background: #eee;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 5px 0px;
      }
    }
  }
}
