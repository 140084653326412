.popup {
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 300px;
    position: relative;
  }

  .popup-image {
    max-width: 20%;
    height: auto;
    border-radius: 5px;
  }

  .popup-title {
    font-size: 1.5rem;
    margin: 10px 0;
  }

  .popup-message {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .popup-close {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .popup-close:hover {
    background-color: #0056b3;
  }
}
