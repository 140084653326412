.swedishToarabic {
  & .headertext {
    height: 50px;
    h5 {
      font-size: 1.1rem;
    }
  }

  & .error {
    color: red;
    font-size: 15px;
    font-weight: bold;
  }

  & .autocomplete {
    margin-top: 30px;
    position: relative;

    & .even {
      background-color: #f9f9f9;
    }

    & div {
      width: 80%;
      position: relative;

      & .bold {
        font-weight: bold;
        color: #000;
      }

      & div {
        width: 100% !important;
        margin: 0px;
        padding: 0px;
        z-index: 10;
        position: absolute !important;
        left: 0 !important;
        bottom: 0 !important;
        top: 43px !important;
        height: 300px !important;
        max-height: 300px !important;

        & div {
          width: auto !important;
          position: relative !important;
          top: 0 !important;
          text-indent: 30px !important;
          height: auto !important;
        }
      }
    }

    & .searchBtn {
      width: 20%;
      float: right;
      padding: 8.5px 0px;
      background: #005293;
      color: gold;
      border-radius: 0px 5px 5px 0px;
      outline: none;
      height: 43px;
    }
    & input {
      width: 100%;
      text-align: right;
      font-family: CairoLight;
      outline: none;
      padding: 0px 10px;
      border-radius: 0px;
      border: 1px solid lightgrey;
      float: right;
      direction: ltr;
      text-align: left;
      float: left;
      height: 43px;
    }
    & .insideautocomplete {
      padding: 5px 0px;
    }

    .odd-item {
      background-color: #f9f9f9; // Light gray background for odd items
      color: #333; // Darker text color
    }

    .even-item {
      background-color: #fff; // White background for even items
      color: #555; // Lighter text color
    }

    .insideautocomplete {
      padding: 10px 0px;
      border-bottom: 1px solid #ddd; // Separator for list items
      cursor: pointer;

      &:hover {
        background-color: #eaeaea; // Highlight on hover
      }
    }

    .bold {
      font-weight: bold;
      color: #007bff; // Highlighted text color
    }
  }

  & .headertext {
  }
  & .closeBtn {
    position: absolute;
    height: 88%;
    padding: 0px 20px;
    font-weight: bold;
    top: -52px;
    background: red;
    color: white;
    font-size: 12px;
    right: 0px;
    border-radius: 0px;
  }
}

$breakpoint-mobile: 320px;
$breakpoint-mobile2: 600px;
$breakpoint-mobile3: 768px;
$breakpoint-mobile4: 1024px;

@media (max-width: $breakpoint-mobile2) {
  .swedishToarabic {
    & .autocomplete {
      & .searchBtn {
        height: 37px;
        line-height: 0;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile4) {
  .swedishToarabic {
    & .searchBtn {
      height: 41px;
      line-height: 0;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .swedishToarabic {
  }
}
