.Testemonials {
  margin-bottom: 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 33%,
    rgba(246, 238, 208, 1) 100%
  );

  & .testemonialsConatiner {
    display: flex;
    flex-direction: row;
  }
  & .whatTheySaid {
    width: 30%;
    & .internal {
      margin: 0%;
      font-size: 6em;
      font-weight: bold;
      text-align: center;
      border-radius: 40px;
      color: #000;
    }
  }
  & .carouselContainer {
    width: 70%;
  }
  & .ar {
    text-align: right;
    direction: rtl;
  }

  & .react-multiple-carousel__arrow--left {
    left: 0px;
    background: #eee;
    top: 0px;
    display: none;
  }
  & .react-multiple-carousel__arrow--right {
    right: 2px;
    background: #eee;
    top: 0px;
    display: none;
  }
  & .react-multiple-carousel__arrow::before {
    font-size: 70px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
    top: 100px;
  }
  & .react-multi-carousel-dot-list {
    bottom: -30px;
  }
  & .react-multi-carousel-list {
    overflow: hidden;
    padding-top: 60px;
  }
  .book.arabic {
    text-align: right;
  }

  & .testemonials {
    text-align: right;
    direction: rtl;
    & .text {
      height: 228px;
      width: 90%;
      border-right: 1px solid #fff;
      padding-right: 3%;
    }
    & .writer {
      font-weight: bold;
      text-align: right;
      padding-left: 10px;
    }

    & .position {
      font-size: 10px;
    }
  }

  $breakpoint-mobile2: 600px;
  @media (max-width: $breakpoint-mobile2) {
    & .testemonialsConatiner {
      flex-direction: column-reverse;
      & .react-multiple-carousel__arrow::before {
        top: 10px;
      }
      & .carouselContainer {
        width: 100%;
      }
      & .react-multi-carousel-list {
        padding-top: 30px;
      }
      & .whatTheySaid {
        width: 100%;
        padding: 10px 0px;
        background: white;
        & .internal {
          font-size: 20px;
        }
      }
    }
  }
}
