.ReactModal__Content.ReactModal__Content--after-open {
  width: 100% !important;
}
.uparrow{
    position: fixed;
    right: 30px;
    bottom: 20px;
cursor: pointer;
}
.uparrow img {
    width: 70px;
    border-radius: 10px;
}