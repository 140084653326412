.AddComment{

    & .label{

        & label{

        }

        & label.ar{
            float: right;
        }
    }

    & .WriteComment{
        width:100%;
        text-align: center;
    }

    & .SubmitComment{

        text-align: center;
        text-align: center;
        margin-top: 20px;
        

    }


}