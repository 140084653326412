.LanguageTabsContainer {
  padding: 0 !important;
}
.LanguageTabs {
  width: 100%;
  margin: 0 auto;
  background: url(../../assets/kofeya.png);
  padding: 20px 0px;
  border-bottom: 1px solid #eee;
  & .tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    font-weight: bold;
    & .tab {
      padding: 10px 5px;
      border-radius: 10px;
      background-color: white;
      border: 1px solid #333333;
    }
    & .pointer {
      cursor: pointer;
      background-color: white;
      border: 1px solid #333333;
    }
    & .active {
      background: #005293;
      color: gold;
    }
    & .pointer:hover {
      background-color: #005293;
      color: gold;
    }
  }
  $breakpoint-mobile2: 600px;
  @media (max-width: $breakpoint-mobile2) {
    & .tabs {
      flex-direction: column;
      text-align: center;
      & .pointer {
        cursor: pointer;
        background-color: white;
        border: 1px solid #333333;
        margin: 10px 10px;
      }
    }
  }
}
